import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import './App.css';
import { CustomCursor } from "react-svg-cursor";
import { BrowserView } from 'react-device-detect';
import svg from "./img/cursor.svg";



function App() {
  return (
    
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          
        <Route path="*" element={<Home />} />
        </Route>
        
      </Routes>
      <BrowserView>
        <CustomCursor
          component={svg}
          isDisabled={false}
          width={35}
          height={35}
          zIndex={10000000}
          transform="translate(-30%, -10%) rotateZ(-22deg)"
        />
        
      </BrowserView>
      
    </BrowserRouter>
  );
}

export default App;
