import React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm from "./CustomForm";



const MailchimpFormContainer = props => {
    const postUrl = `https://alexiapaupart.us17.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

    return (
        <div className="flex flex-col">
            
            <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status} 
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
            <p className='mt-5 text-xs tracking-normal sm:mt-2'>En m'inscrivant à la newsletter, j'accepte de recevoir des informations par mail d'AlexiaPaupart</p>
        </div>
    );
};

export default MailchimpFormContainer;