import logo from '../img/1.svg'
import background from '../img/2.svg'

import MailchimpFormContainer from "./Mailchimp.js";

function Home() {
    return (
      
        <div className="flex h-screen flex-col items-center justify-center text-center align-middle tracking-normal pl-5 pr-5 ">


        <div className='mb-12 font-black text-[36px]'>
            <h1 className="">
            Patience, 
            <br></br>
            la boutique Paupart arrive bientôt
            </h1>
        </div>

        <div className='mb-8 text-[18px]'>
          <p className="mb-3">
          Toujours disponible sur
          <a href='https://fr.ankorstore.com/brand/paupart-29314' target="_blank" rel="noreferrer">
            <span className='font-black transition-all underline underline-offset-4 hover:italic hover:opacity-40'> Ankorstore </span>
          </a>
            &
            <a href='https://www.faire.com/brand/b_84e3pvwaak?refReqId=x7d89xvgqgc8dn9w6jy7rcqqp&refType=SUGGESTIONS_BRANDS&signUp=auto' target="_blank" rel="noreferrer">
              <span className='font-black transition-all underline underline-offset-4 hover:italic hover:opacity-40'> Faire </span>
            </a>
              
            ou sur mon <a href='https://www.etsy.com/fr/shop/PaupArt' target="_blank" rel="noreferrer"><span className='font-black underline underline-offset-70 hover:italic hover:opacity-40 transition-all'> Etsyshop </span></a>
          </p>

          <p className="">
          Pss.. rejoins moi sur <a href='https://www.instagram.com/paup._.art/' target="_blank" rel="noreferrer"><span className='transition-all font-black italic underline underline-offset-4 hover:not-italic hover:opacity-40' >Instagram</span></a>
          </p>

          
        </div>




        <MailchimpFormContainer />
      <img src={logo} alt='Logo scarabée AlexiaPaupart' className='mt-10 hidden sm:block'/>
      <img src={background} alt='Background boutique AlexiaPaupart scarabée' className='absolute opacity-5 -z-50' />
  </div>
  
    );
  }
  
  export default Home;
