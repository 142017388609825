import React, {useState, useEffect } from 'react';
import InputField from "./InputField";

const CustomForm = ({ status, message, onValidated }) => {
    
    const [email, setEmail] = useState('');


    useEffect(() => {
        if(status === "success") clearFields();
      }, [status])
    
      const clearFields = () => {
        setEmail('');
      }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
        email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email,
        });
    }

    return (
      <form  className="" onSubmit={(e) => handleSubmit(e)}>
        <h3 className="font-black mb-5 sm:mb-0">
          {status === "success" 
            ? "Inscription réussie !" 
            : "Inscrivez-vous à notre newsletter pour être informé du lancement de la boutique Paupart !"
          }
        </h3>


        {status === "sending" && (
          <div className="">
            Envoi...
          </div>
        )}

        {status === "error" && (
          <div 
            className=""
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}


        {status === "success" && (
          <div
            className=""
          />
        )}

        {status !== "success" ? (
            <div className="mt-2 md:space-x-5">

              <InputField
                  label="Adresse Mail :"
                  onChangeHandler={setEmail}
                  type="email"
                  value={email}
                  placeholder="votre@email.com"
                  isRequired
              />
              <InputField
                 label="S'inscrire"
                 type="submit"
                 formValues={[email]}
               />
            </div>
        ) : null}

        {/* {
          status === 'success' ? <button
            className=""></button> : <InputField
                 label="S'inscrire"
                 type="submit"
                 formValues={[email]}
               />
        } */}



      </form>
    );
};
export default CustomForm;